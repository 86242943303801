<div class="breadcrumb">
    <h1>Panel configuration</h1>
<!--    <ul [ngSwitch]="menuIndex">-->
<!--        <ng-container *ngFor="let menu of menuItems">-->
<!--            <li ><a href="javascript:null" [innerHTML]="menu.label"-->
<!--                    [class.text-primary]="menuIndex == menu.index"-->
<!--                    (click)="menu.command()"-->
<!--            ></a></li>-->
<!--        </ng-container>-->
<!--    </ul>-->
</div>

<div class="separator-breadcrumb border-top"></div>

<div class="row mb-3">
    <div class="col-md-12 mb-3">

    </div>
    <div class="col-md-12">
        <div class="card o-hidden">

        </div>
    </div>
</div>

