import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

// import ws from './settings.json';

@Component({
    selector: 'app-window-list-ui',
    templateUrl: './window-list-ui.component.html',
    styleUrls: ['./window-list-ui.component.scss']
})
export class WindowListUiComponent implements OnInit {

    @Input() windowid: string;
    @Output() onSave = new EventEmitter();
    @Output() onCancel = new EventEmitter();

    // definition:

    constructor(
        private modalService: NgbModal,
        private toastr: ToastrService
    ) { }

    ngOnInit() {
        //this.loadDefinition(this.windowid);
    }

    // loadDefinition(wid: string) {
    //
    // }



}
