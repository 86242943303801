import {Injectable} from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class ConfigService {

    public apptitle: string = 'QPanel';
    public appname: string = '<span class="text-primary px-2">Q</span><span class="color-black">Panel</span>';
    public appiconwhite: string = '<span class="text-white px-2">Q</span>';
    public appicon: string = '<span class="text-primary px-2">Q</span>';
    public loading: boolean = false;
    public loading1: boolean = false;
    public showcode: boolean = false;
    public showadditionalinfo: boolean = false;



    public system_text = {
        'err_daily_summary': '<p class="m-0 p-0">Dopo l\'ultima modifica si è verificata una anomalia con gli orari indicati: sono evidenziati in rosso nel riepilogo gli orari non coerenti.</p><p class="m-0 p-0">Non potrai continuare a compilare il diario fino a che gli orari non saranno coerenti. Seleziona una tra le opzioni di seguito riportate.</p>',
        'create_new_activity': 'Crea una nuova attività',
        'move_up_following': 'Anticipa l\'attività seguente ed aumentane la durata',
        'move_up_all': 'Anticipa l\'attività seguente e tutte le attività successive',
        'postpone_following': 'Posticipa l\'attività seguente e riducine la durata',
        'postpone_all': 'Posticipa l\'attività seguente e tutte le attività successive',
        'delete_all_activities': 'Elimina tutte le attività',
        'attention': 'Attenzione',
        'reset_diary_confirm': '<p><strong>Sei davvero sicuro di voler rimuovere tutte le attività per iniziare nuovamente la compilazione del diario?</strong></p><p><span class="text-danger">Questa operazione non potrà essere annullata</span></p>',
        'cancel': 'Annulla',
        'confirm': 'Conferma',
        'slider_hint': '<p class="text-small text-primary m-0 p-0">Lo slider si colora quando una risposta è stata selezionata. Il valore scelto viene indicato in basso. </p><p class="text-small text-primary m-0 p-0 mb-3">Per impostare il valore 0, devi trascinare a destra e poi nuovamentea sinistra il cursore.</p>',
        'text_mandatory': 'Devi inserire tutti i dati richiesti per attivare il pulsante Avanti e continuare',
        'next': 'Avanti',
        'back': 'Indietro',
        'activity': 'Attività',
        'edit': 'Modifica',
        'points_assigned': 'Punti assegnati su 100 totali: ',
        'points_gt_50': 'Hai attribuito più del 50% ad una singola riga',
        'sum_not_100': 'La somma dei punteggi assegnati non è uguale a ',
        'error_hours_coincident': 'L\'orario di inizio non può coincidere con quello di fine',
        'error_hours_not_follows': 'L\'orario di fine deve essere successivo a quello di inizio',
        'error_hours_over': 'Il diario deve finire alle 04:00. Seleziona i minuti \'00\' per proseguire.',
        'openended_hint': 'Inserisci un testo di minimo {x} caratteri',
        'diary_summary_completed': 'Hai completato il diario! Fai click su Avanti per proseguire.'
    };
    public system_text_en = {
        'err_daily_summary': '<p class="m-0 p-0">An anomaly occurred with the indicated times: the inconsistent times are highlighted in red in the summary.</p><p class="m-0 p-0">You will not be able to continue filling out the diary until the times are consistent. Select one of the options listed below.</p>',
        'create_new_activity': 'Create new activity',
        'move_up_following': 'Move up the following activity and increase its duration',
        'move_up_all': 'Move up the following activity and all subsequent activities',
        'postpone_following': 'Postpone the following activity and reduce its duration',
        'postpone_all': 'Postpone the following activity and all subsequent activities',
        'delete_all_activities': 'Delete all activities',
        'attention': 'Attention',
        'reset_diary_confirm': '<p><strong>Are you really sure you want to remove all activities to start filling out the diary again?</strong></p><p><span class="text-danger">This operation cannot be undone</span></p>',
        'cancel': 'Cancel',
        'confirm': 'Confirm',
        'slider_hint': '<p class="text-small text-primary m-0 p-0">The slider changes color when a response has been selected. The chosen value is indicated at the bottom. </p><p class="text-small text-primary m-0 p-0 mb-3">To set the value to 0, you need to drag the cursor to the right and then back to the left.</p>',
        'text_mandatory': 'You need to enter all the required data to activate the \'Next\' button and continue.',
        'next': 'Next',
        'back': 'Back',
        'activity': 'Activity',
        'edit': 'Edit',
        'points_assigned': 'Points assigned of 100 total: ',
        'points_gt_50': 'More than 50% assigned to a single line',
        'sum_not_100': 'The sum is not equal to ',
        'error_hours_coincident': 'The start time cannot be the same of the end time',
        'error_hours_not_follows': 'The end time must be after the start time',
        'error_hours_over': 'The diary must end at 04:00. Select the \'00\' minutes to proceed.',
        'openended_hint': 'Enter a text of at least {x} characters',
        'diary_summary_completed': 'You have completed your diary! Click Next button to continue.'
    };





}
