<div class="app-footer">
	<div class="row">
		<div class="col-md-9">
            <p><small><img src="assets/images/footer.png" style="width: 50%"/></small></p>

		</div>
	</div>
	<div class="footer-bottom border-top pt-3 d-flex flex-column flex-sm-row align-items-center">
<!--        <a class="btn btn-primary text-white btn-rounded mb-3 mb-sm-0 me-0 me-sm-3"-->
<!--        href=""-->
<!--        target="_blank">Video illustra</a>-->
<!--        <a class="btn btn-warning btn-rounded mb-3 mb-sm-0"-->
<!--        href=""-->
<!--        target="_blank">Feedback</a>-->

		<span class="flex-grow-1"></span>

<!--        <div class="d-flex align-items-center">-->
<!--            <div>-->
<!--                <label class="checkbox checkbox-outline-primary">-->
<!--                    <input type="checkbox" checked="" [ngModel]="config.showcode">-->
<!--                    <span>Codici domanda</span>-->
<!--                    <span class="checkmark"></span>-->
<!--                </label>-->
<!--            </div>-->
<!--            <div class="ms-3">-->
<!--                <label class="checkbox checkbox-outline-primary">-->
<!--                    <input type="checkbox" checked="" [ngModel]="config.showadditionalinfo">-->
<!--                    <span>Info addizionali</span>-->
<!--                    <span class="checkmark"></span>-->
<!--                </label>-->
<!--            </div>-->



<!--        </div>-->
<!--		<div class="d-flex align-items-center">-->
<!--&lt;!&ndash;			<img class="logo" src="./assets/images/logo.png" alt="">&ndash;&gt;-->
<!--			<h1 [innerHTML]="config.appicon"></h1>-->
<!--			<div>-->
<!--				<p class="m-0">&copy; 2023 Strategoi</p>-->
<!--				<p class="m-0">All rights reserved</p>-->
<!--			</div>-->
<!--		</div>-->
	</div>
</div>
